export const selectAllAvatar = (state) => state.userSynthetic.avatars;
export const selectAllSharedAvatar = (state) => state.userSynthetic.sharedAvatars;
export const selectAllLanguage = (state) => state.userSynthetic.languages;
export const selectCreateLanguage = (state) => state.userSynthetic.createLanguage;
export const selectUpdateLanguage = (state) => state.userSynthetic.updateLanguage;
export const selectGetLanguageById = (state) => state.userSynthetic.getLanguageById;
export const selectAllVoice = (state) => state.userSynthetic.voices;
export const selectCreateVoice = (state) => state.userSynthetic.createVoice;
export const selectUpdateVoice = (state) => state.userSynthetic.updateVoice;
export const selectAllPartners = (state) => state.userSynthetic.allPartners;
export const selectGetVoiceById = (state) => state.userSynthetic.getVoiceById;
export const selectAllSynthetic = (state) => state.userSynthetic.synthetics;
export const selectIsLoadingCreateSyn = (state) => state.userSynthetic.isLoadingCreateSyn;
export const selectIsLoadingUploadAvatar = (state) => state.userSynthetic.isLoadingUploadAvatar;
export const selectIsLoadingUpdateTask = (state) => state.userSynthetic.isLoadingUpdateTask;
export const selectIsDeleting = (state) => state.userSynthetic.isDeletingSyn;
export const selectIsError = (state) => state.userSynthetic.error;
export const selectErrorMessage = (state) => state.userSynthetic.errorExistMessage;
export const selectSucCreateSyn = (state) => state.userSynthetic.isCreateSynSuccess;