import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import styles from "./accountSynthetic.module.css";
import { selectAllSynthetic, selectIsDeleting } from "./selectors";
import { loadAllSynthetic } from "./userSyntheticSlice";
import PreLoading from "../../components/pre_loading/PreLoading";
import CommonErrorWindow from "../../components/popup_windows/common_error_window/CommonErrorWindow.jsx";

export default function AccountSynthetic() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const synthetics = useSelector(selectAllSynthetic);
  const isDeleting = useSelector(selectIsDeleting);
  const [controlLoad, setLoad] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button1");
  const [videoRun, setVideoRun] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [volume, setVolume] = useState(1);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const initialized = useRef(false);

  const instructionVideo = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video/Instruction.mp4";

  const handleCreateSynthetic = () => {
    navigate("/account/mySynthetic/create");
  };

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleOnClick = () => {
    setVideoRun(true);
    setAutoPlay(true);
  };

  const handleVideoEnded = () => {
    setAutoPlay(false);
    setVideoRun(false);
  };

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  const handleProgressUpdate = () => {
    const currentProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(currentProgress);
  };

  const handleProgressChange = (event) => {
    const newTime = (event.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
    setProgress(event.target.value);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleViewAllDetails = (synId) => {
    navigate(`/account/mySynthetic/${synId}`);
  };

  const getSynthetics = useCallback(() => {
    if (synthetics.length === 0) {
      dispatch(loadAllSynthetic());
    }
  }, [dispatch, synthetics]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getSynthetics();
    }
    const updateIsMobile = () => {
      const maxWidth = Math.max(window.innerWidth, window.innerHeight);
      setIsMobile(maxWidth < 1024);
    };
    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, [getSynthetics]);

  if (isMobile) {
    return (
      <div className={styles.mobileWarningBody}>
        <div className={styles.mobileWarning}>
          <p className={styles.mobileMessage}>{t("mobileWarning")}</p>
          <button className={styles.buttonWarning} onClick={handleGoHome} type="button" id="warningButton">
            <div className={styles.buttonWarningText}>
              <span>{t("buttonBackMobile")}</span>
            </div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "button1")}
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>My Synthetic</span>
              </div>
            </div>
          </button>
          <button
            className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/payment/pricing", "button2")}
            onMouseEnter={() => handleMouseEnter("button2")}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Payment</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      {isDeleting ? (
        <PreLoading />
      ) : (
        <div className={styles.syntheticsBox}>
          {synthetics.map((syn) => {
            if (!syn.hide) {
              return (
                <div key={syn.syntheticUuid} className={styles.syntheticCard}>
                  <button
                    id={syn.syntheticUuid}
                    type="button"
                    title="view all details about synthetic"
                    className={styles.syntheticButton}
                    onClick={() => handleViewAllDetails(syn.syntheticUuid)}
                  >
                    <img src={`data:image/*;base64,${syn.avatar}`} alt={syn.name} />
                  </button>
                  <p className={styles.syntheticName} htmlFor={syn.syntheticUuid}>
                    {syn.name}
                  </p>
                </div>
              );
            }
            return (
              <CommonErrorWindow
                key={`error-${syn.syntheticUuid}`}
                open
                onClose={() => {}}
                text="Synthetic item is hidden or unavailable."
                redirectToLogin={false}
              />
            );
          })}
          <div className={styles.syntheticCard}>
            <button
              id="createBtn"
              type="button"
              title="create new synthetic"
              className={styles.createSynthetic}
              onClick={handleCreateSynthetic}
            >
              +
            </button>
            <p htmlFor="createBtn">Create new Synthetic</p>
          </div>
        </div>
      )}

      <div className={styles.instructionBox}>
        <h3 className={styles.headerInstruction}>
          <HelpIcon />
          <span>Video instruction</span>
        </h3>
        {videoRun ? (
          <div className={styles.videoInstructionBox}>
            <video
              ref={videoRef}
              className={styles.videoInstruction}
              src={instructionVideo}
              preload="metadata"
              playsInline
              type="video/mp4"
              autoPlay={autoPlay}
              onEnded={handleVideoEnded}
              onTimeUpdate={handleProgressUpdate}
            >
              Your browser does not support the video tag.
            </video>
            <div className={styles.controls}>
              <button onClick={togglePlayPause}>{videoRef.current?.paused ? "Play" : "Pause"}</button>
              <input type="range" min="0" max="100" value={progress} onChange={handleProgressChange} />
              <input type="range" min="0" max="1" step="0.1" value={volume} onChange={handleVolumeChange} />
            </div>
          </div>
        ) : (
          <button type="button" className={styles.videoInstructionBox} onClick={handleOnClick}>
            <img className={styles.videoInstruction} src="/tempVideo.jpg" alt="Play Instruction Video" />
          </button>
        )}
      </div>
    </div>
  );
}
